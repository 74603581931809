























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InvoiceTable extends Vue {
  @Prop({ type: String })
  public company?: string;

  public get fields() {
    return [
      { key: 'nr', label: '#', sortable: true },
      { key: 'date', label: this.$t('paid-date'), sortable: true },
      { key: 'license', label: this.$t('license'), sortable: true },
      { key: 'price', label: this.$t('price'), sortable: true },
      { key: 'start', label: this.$t('date-start') },
      { key: 'end', label: this.$t('date-end') },
      {
        key: 'actions',
        label: '',
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ];
  }

  public get items() {
    return this.$company.invoices
      .map(invoice => {
        const company = this.$company.find(invoice.company);
        return (company && { company, invoice })!;
      })
      .filter(data => {
        if (!data || !data.invoice.license) return false;
        return this.company ? this.company === data.company.id : true;
      })
      .map(({ invoice, company }) => ({
        company: company.name,
        date: invoice.date.toDate().toLocaleDateString(),
        end: invoice.end ? invoice.end.toDate().toLocaleDateString() : '',
        id: invoice.id,
        license: this.$t(`license-${invoice.license}`),
        mail: company.email,
        nr: invoice.nr,
        pdf: `/company/${invoice.company}/invoice/${invoice.id}.pdf`,
        price: `€ ${invoice.price}`,
        start: invoice.start ? invoice.start.toDate().toLocaleDateString() : '',
      }));
  }

  public async download(path: string) {
    const downloadUrl = await this.$firebase.file(path).getDownloadURL();

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.click();
  }
}
