















import { Company } from '@app/models';
import fb from 'firebase/app';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import CompanySettings from '../company/company-settings.vue';
import { fileManager } from '../../store/modules/storage-module';

@Component({
  components: { CompanySettings },
})
export default class CompanyCreate extends Vue {
  public company: Company = null as any;

  public async created() {
    await this.$user.ready;
    this.company = this.emptyCompany();
  }

  public discard() {
    const group = `company-${this.company.id}`;
    for (const file of this.company.logo) fileManager.delete(file, { group });
    this.$router.back();
  }

  public async save() {
    const form = this.$el.querySelector('bce-form');
    const errors = form ? await form.validate() : [];
    if (errors.length) return;

    if (!this.company.created)
      this.company.created = fb.firestore.Timestamp.now();
    await this.$firebase.doc(`company/${this.company.id}`).set(this.company);
    this.$router.back();
  }

  private emptyCompany(): Company {
    // 30 days from now
    const now = fb.firestore.Timestamp.now();
    const seconds = now.seconds + 30 * 24 * 60 * 60;
    const until = new fb.firestore.Timestamp(seconds, now.nanoseconds);
    const id = this.$firebase.generateId();

    return {
      auth: {},
      name: '',
      categories: [],
      complete: true,
      cid: id,
      created: now,
      id,
      roles: [...this.$company.appRoles],
      email: '',
      users: [],
      license: { current: '', request: '', until },
      logo: [],
      color: '',
      color2: '',
      invoice: { address: '', postalCode: '', state: '' },
      phone: '',
    };
  }
}
