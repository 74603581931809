















































































































import { Company } from '@app/models';
import { getColorShade } from '@bcase/core';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import InvoiceTable from '../../components/5-table/invoice-table.vue';

@Component({
  components: { InvoiceTable },
})
export default class CompanySettings extends Vue {
  @Prop({ type: Object })
  public company!: Company;

  public get editable() {
    return (
      this.$company.permission('company-settings') ||
      (!this.company.complete && this.company.users.indexOf(this.$user.id) >= 0)
    );
  }

  public get color() {
    const { color } = this.company;
    const [c1, c2] = (color ? color : this.defaultColor).split(' ');
    return `${c1} ${c2 || '#ffffff'}`;
  }

  public get color2() {
    const { color2 } = this.company;
    const [c1, c2] = (color2 ? color2 : this.defaultColor2).split(' ');
    return `${c1} ${c2 || '#ffffff'}`;
  }

  public get defaultColor() {
    const htmlRoot = document.querySelector(':root') as HTMLElement;
    return getColorShade(htmlRoot, 'primary');
  }

  public get defaultColor2() {
    const htmlRoot = document.querySelector(':root') as HTMLElement;
    return getColorShade(htmlRoot, 'secondary');
  }

  @Watch('$route')
  public watchRoute() {
    this.watchCategories();
  }

  @Watch('company.logo')
  public watchLogo(logo: string[]) {
    const upload = this.$refs['logo'] as HTMLBceUploadElement;
    if (!upload) return;

    upload.value = logo;
    upload.metadata = {
      ref: `company/${this.company.id}/logo.png`,
      group: `company-${this.company.id}`,
    };
  }

  @Watch('company.categories', { deep: true })
  public watchCategories() {
    for (let i = 0; i < this.company.categories.length; i++) {
      const category = this.company.categories[i];
      const ref = `category-${i}`;
      const select = this.$refs[ref] as HTMLBceSelectElement[] | undefined;
      if (select && select[0]) select[0].value = category.options;
    }
  }

  public mounted() {
    this.watchLogo(this.company.logo);
    this.watchCategories();
  }

  public async categoryAdd() {
    const name = window.prompt(this.$t('category-name'), '');
    if (!name) return;

    const value = [...this.company.categories, { name, options: [] }];
    Vue.set(this.company, 'categories', value);
    this.$forceUpdate();

    setTimeout(() => {
      const inputs = this.$el.querySelectorAll('bce-select[type=input]');
      const input = Array.from(inputs).pop();
      if (input) input.shadowRoot!.querySelector('input')!.focus();
    }, 200);
  }

  public categoryRemove(index: number) {
    const value = [...this.company.categories];
    value.splice(index, 1);
    Vue.set(this.company, 'categories', value);
    this.$forceUpdate();
  }

  public categoryRename(index: number) {
    const name = window.prompt(this.$t('category-name'), '');
    if (!name) return;

    const value = [...this.company.categories];
    value[index].name = name;
    Vue.set(this.company, 'categories', value);
    this.$forceUpdate();

    setTimeout(() => this.watchCategories(), 200);
  }

  public categorySet(index: number, options: string[]) {
    const value = [...this.company.categories];
    value[index].options = options;
    Vue.set(this.company, 'categories', value);
    this.$forceUpdate();
  }
}
